import store from '@/store'

import router from '@/router'

export default [
  {
    path: '/main',
    name: 'Main',
    component: () => import('@/views/Main.vue'),
    meta: {
      pageTitle: '메인'
    }
  },
  {
    path: '/bidPrices',
    name: 'BidPrices',
    component: () => import('@/views/BidPrices.vue'),
    meta: {
      pageTitle: '입찰가 관리'
    },
    beforeEnter: (to, from, next) => {
      const isGuest = sessionStorage.getItem('guest') === 'Y'
      if (isGuest) {
        alert('입찰가 관리는 대행사에 직접 문의 주시기 바랍니다.')
        router.push('/main')
      } else next()
    }
  },
  {
    path: '/ageBidWeights',
    name: 'AgeBidWeights',
    component: () => import('@/views/AgeBidWeights.vue'),
    meta: {
      pageTitle: '연령대 가중치'
    },
  },
  {
    path: '/genderBidWeights',
    name: 'GenderBidWeights',
    component: () => import('@/views/GenderBidWeights.vue'),
    meta: {
      pageTitle: '성별 가중치'
    },
  },
  {
    path: '/bidLogs',
    name: 'BidLogs',
    component: () => import('@/views/BidLogs.vue'),
    meta: {
      pageTitle: '입찰로그'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      pageTitle: '로그인',
      layout: 'full'
    }
  },
  {
    path: '/sample/table',
    name: 'SampleTabe',
    component: () => import('@/views/sample/SampleTable.vue'),
    meta: {
      pageTitle: '샘플 > 테이블'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full'
    }
  }
]
