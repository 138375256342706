<template>
  <div class="gender-bid-weights">
    <!-- 필터터 -->
    <div class="search-filter-form">
      <div class="filter-area d-flex">
        <div class="gender-bid-weights__select select mt-0">
          <b-form-select 
            v-model="keyword"
            class="inner_select"
            :options="keywords"
            value-field="code"
            text-field="name"
            @change="handleChangeKeyword"
          ></b-form-select>
        </div>
      </div>
    </div>

    <!-- 테이블 -->
     <div class="card mt-1 mb-0">
      <b-table-simple
        :class="{
          nodata: isInitialized && bidWeightItems.length === 0,
          load_ing: !isInitialized && loading
        }"
        class="adjust-table"
        table-class="text-center"
        fixed
        responsive 
        sticky-header
      >
        <!-- 테이블 헤더 -->
        <b-thead>
          <b-tr class="bor_btm_grey">
            <b-th class="text-center p-0" rowspan="1" colspan="1" style="width: 40px;">
              <span class="word-keep-all">
                기기<br class="d-lg-none" />
                <span class="d-none d-md-inline">/</span>상태
              </span>
            </b-th>
            <b-th class="text-center p-0" rowspan="1" colspan="1" style="width: 80px;">
              성별
            </b-th>
            <b-th class="text-center p-0" rowspan="1" colspan="1"  style="width: 80px;">
              <span class="word-keep-all">현재 가중치</span>
            </b-th>
            <b-th class="text-center p-0" rowspan="1" colspan="1" style="width: 100px;">
              <span class="word-keep-all">
                가중치 변경
              </span>
            </b-th>
          </b-tr>
        </b-thead>

        <!-- 테이블 바디 -->
        <b-tbody role="rowgroup">
          <template v-for="(item, idx) in bidWeightItems">
            <b-tr :key="`row-${idx}`">
              <b-td>
                <div class="d-flex flex-column flex-md-row gap-.5 justify-content-center align-items-center">
                  <img class="cell-img" v-if="item.device === 'PC'" src="@/assets/images/custom/pc.png" />
                  <img class="cell-img" v-else-if="item.device === 'M'" src="@/assets/images/custom/mobile.png" />
                  <img class="cell-img" v-else src="@/assets/images/custom/mobile_more.png" />
                  <span class="d-none d-md-inline"> / </span>
                  <img v-if="item.active" class="cell-img" src="@/assets/images/custom/media_play.png" />
                  <img v-else class="cell-img" src="@/assets/images/custom/media_stop_red.png" />
                </div>
              </b-td>

              <b-td>
                {{ item.gender }}
              </b-td>

              <b-td>
                {{ item.bidWeight === null ? '-' : `${item.bidWeight}%` }}
              </b-td>
              <b-td>
                <div class="gender-bid-weights__control">
                  <b-overlay :show="getUpdateLoadingState(item.id) === 'increase'" spinner-small>
                    <b-button
                      :disabled="item.bidWeight === null" 
                      variant="outline-dark"
                      size="sm"
                      title="가중치 1퍼센트 증가"
                      @click="handleClickUpdateBtn(item, 1)"
                    >
                      <span>&plus;&nbsp;1%</span>
                    </b-button>
                  </b-overlay>

                  <b-overlay :show="getUpdateLoadingState(item.id) === 'decrease'" spinner-small>
                    <b-button 
                      :disabled="item.bidWeight === null"
                      variant="outline-dark"
                      size="sm"
                      title="가중치 1퍼센트 감소"
                      @click="handleClickUpdateBtn(item, -1)"
                    >
                      <span>&hyphen;1%</span>
                    </b-button>
                  </b-overlay>
                </div>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
     </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isInitialized: false,
      keyword: null,
      fields: [
        {
          key: 'status',
          label: '기기/상태',
          thStyle: 'width: 120px',
          thClass: 'p-0',
          tdClass: 'p-0'
        },
        {
          key: 'keyword',
          label: '키워드',
          thStyle: 'width: 120px',
          thClass: 'p-0',
          tdClass: 'p-0'
        },
        {
          key: 'age',
          label: '연령대',
          thStyle: 'width: 120px',
          thClass: 'p-0',
          tdClass: 'p-0'
        },
        {
          key: 'rank',
          label: '최근 순위',
          thStyle: 'width: 120px',
          thClass: 'p-0',
          tdClass: 'p-0'
        },
        {
          key: 'weight',
          label: '현재 가중치',
          thStyle: 'width: 120px',
          thClass: 'p-0',
          tdClass: 'p-0'
        },
        {
          key: 'manage',
          label: '가중치 변경',
          thStyle: 'width: 120px',
          thClass: 'p-0',
          tdClass: 'p-0'
        },
      ],

      bidWeightItems : [],

      /**
       * 가중치 증가/감소 로딩 상태를 관리
       * 
       * [item.id]: 'increase' | 'decrease' | null
       */
      updateLoadingStates: {}
    }
  },

  computed: {
    ...mapGetters('common', ['loading']),
    keywords() {
      return this.$store.getters['bidWeights/keywords'].map((item, index) => ({
        id: index,
        name: item.Keyword,
        code: item.Keyword,
      }))
    },

    timeFields() {
      if (this.bidWeightItems.length === 0) {
        return [{
          key: 'rank1',
          label: '1타임',
        }, {
          key: 'rank2',
          label: '2타임',
        }, {
          key: 'rank3',
          label: '3타임',
        }]
      }

      return this.bidWeightItems[0].ranks.map((rank, index) => {
        const date = new Date(rank.date)
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return {
          key: `rank${index + 1}`,
          label: `${hours}:${minutes}`,
        }
      })
    },
  },

  methods: {
    ...mapMutations('common', ['SET_LOADING']),

    // api
    async fetchKeywords() {
      await this.$store.dispatch('bidWeights/fetchBidWeightsKeywords')
    },

    async fetchBidWeights({ keyword }) {
      return await this.$store.dispatch('bidWeights/fetchGenderBidWeights', { keyword })
    },

    async fetchCurrentBidWeights({ customerId, groupId }) {
      return await this.$store.dispatch('bidWeights/fetchCurrentGenderBidWeights', { customerId, groupId })
    },

    createParams(items = []) {
      // 1. customerId와 groupId만 추출
      const params = items.map(item => ({
        customerId: item.customerId,
        groupId: item.groupId
      }))

      // 2. null 값을 가진 객체 제거
      const filteredParams = params.filter(param => 
        !!param.customerId && !!param.groupId
      )

      // 3. 중복 제거
      const uniqueParams = filteredParams.filter((param, index, self) =>
        index === self.findIndex(p => 
          p.customerId === param.customerId && 
          p.groupId === param.groupId
        )
      )

      return uniqueParams
    },

    async fetchAllCurrentBidWeights(params = []) {
      const promises = params.map(
        (param) => this.fetchCurrentBidWeights(param)
      )

      const responses = await Promise.all(promises)

      return params.map((param, index) => ({
        groupId: param.groupId,
        dictionary: responses[index],
      }))
    },

    applyCurrentBidWeights(items = [], currentWeightResponses = []) {
      return items.map((it) => {
        // groupId, dictionaryCode를 이용해서 현재 가중치를 찾습니다
        const group = currentWeightResponses.find((item) => item.groupId === it.groupId)
        const dictionary = group ? group.dictionary : {}
        const bidWeight = dictionary[it.dictionaryCode]?.bidWeight || null

        return {
          ...it,
          bidWeight,
          id: `${it.groupId}-${it.dictionaryCode}`,
        }
      })
    },

    sortItemsByDevice(items = []) {
      // pc, mobile 순으로 정렬
      const orders = ['pc', 'm']

      items.sort((a, b) => {
        const aIndex = a.device ? orders.indexOf(a.device.toLowerCase()) : -1
        const bIndex = b.device ? orders.indexOf(b.device.toLowerCase()) : -1
        return aIndex - bIndex
      })
    },

    async fetchAllData(keyword) {
      try {
        const items = await this.fetchBidWeights({ keyword })
        const params = this.createParams(items)
        const currentWeightResponses = await this.fetchAllCurrentBidWeights(params)
  
        // items에 현재 가중치 적용
        const result = this.applyCurrentBidWeights(items, currentWeightResponses)
  
        // pc, mobile 순으로 정렬
        this.sortItemsByDevice(result)
  
        this.bidWeightItems = result
      } catch (error) {
        console.error('fetchBidWeights failed: ', error)

        this.bidWeightItems = []

        throw new Error('데이터 조회에 실패했습니다.')
      }
    },

    async updateBidWeights(row, amount = 1) {
      try {
        this.setUpdateLoadingState(row.id, amount > 0 ? 'increase' : 'decrease')

        const nextBidWeight = row.bidWeight + amount

        await this.$store.dispatch('bidWeights/updateAgeBidWeights', {
          customerId: row.customerId,
          groupId: row.groupId,
          dictionaryCode: row.dictionaryCode,
          bidWeight: nextBidWeight,
        })

        // 로컬 상태 업데이트
        this.bidWeightItems = this.bidWeightItems.map((item) => {
          if (item.id !== row.id) return item

          return {
            ...item,
            bidWeight: nextBidWeight,
          }
        })
      } catch (error) {
        console.error('updateBidWeights failed: ', error)

        alert('가중치 변경에 실패했습니다.')
      } finally {
        this.setUpdateLoadingState(row.id, null)
      }
    },

    /**
     * 가중치 변경 진행중인 아이템
     * @param row 
     */
    isUpdateLoading(row) {
      const state = this.getUpdateLoadingState(row.id)
      return state === 'increase' || state === 'decrease'
    },

    getUpdateLoadingState(id) {
      return this.updateLoadingStates[id] || null
    },

    setUpdateLoadingState(id, state) {
      this.$set(this.updateLoadingStates, id, state)
    },

    async init() {
      try {
        this.SET_LOADING(true)

        await this.fetchKeywords()

        // 키워드 선택
        if (this.keywords.length > 0) {
          this.keyword = this.keywords[0].code

          await this.fetchAllData(this.keyword)
        }
      } catch (error) {
        console.error('init failed: ', error)
      } finally {
        this.SET_LOADING(false)
      }
    },

    async handleChangeKeyword(selectedKeyword) {
      try {
        this.SET_LOADING(true)
        await this.fetchAllData(selectedKeyword)
      } catch (error) {
        console.error('handleChangeKeyword failed: ', error)
      } finally {
        this.SET_LOADING(false)
      }
    },

    async handleClickUpdateBtn(row, amount = 1) {
      if (row.bidWeight === null) {
        return
      }

      // 요청 진행중 또는 현재 가중치가 없을 경우
      if (this.isUpdateLoading(row)) {
        return
      }

      const ok = confirm('변경하시겠습니까?')
      if (!ok) return

      await this.updateBidWeights(row, amount)
    },
  },

  created () {
    this.init().then(() => {
      this.isInitialized = true
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/base/bootstrap-extended/include';

  .gender-bid-weights {
    .b-table-sticky-header {
      thead {
        tr {
          &:nth-child(2) th {
            top: 2.75rem;
          }

          th {
            padding: 0;
            // border: 0.2rem solid #f3f2f7;
          }
        }
      }
  
      tbody {
        tr {
          td {
            padding: 8px 0;
          }
        }
      }
    }

    &__select {
      flex-grow: 1;
    }

    &__control {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .btn {
        min-width: 44px;
        padding: {
          left: 0;
          right: 0;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      &__select {
        flex-grow: initial;
      }
    }
  }
</style>