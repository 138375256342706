import { createApiV1Store } from '@/api'

const apiStore = createApiV1Store([
  {
    action: '_fetchBidWeightsKeywords',
    path: 'mweb/bidding-price/bid-Weight-keyword',
    method: 'get',
    onSuccess (state, payload) {
      state.keywords = payload.data.Body || []
    }
  },
  {
    action: '_fetchAgeBidWeights',
    path: 'mweb/bidding-price/bid-weight/age-group',
    method: 'get',
  },
  {
    action: '_fetchCurrentAgeBidWeights',
    path: 'mweb/bidding-price/bid-weight/age-group/{customerId}/{groupId}',
    pathParams: ['customerId', 'groupId'],
    method: 'get',
  },
  {
    action: '_fetchGenderBidWeights',
    path: 'mweb/bidding-price/bid-weight/gender',
    method: 'get',
  },
  {
    action: '_fetchCurrentGenderBidWeights',
    path: 'mweb/bidding-price/bid-weight/gender/{customerId}/{groupId}',
    pathParams: ['customerId', 'groupId'],
    method: 'get',
  },
  {
    action: '_updateAgeBidWeights',
    path: 'mweb/bidding-price/bid-weight/{customerId}/{groupId}',
    pathParams: ['customerId', 'groupId'],
    method: 'put',
  },
])

export default {
  namespaced: true,
  mixins: [apiStore],
  state: {
    keywords: [],
  },
  getters: {
    keywords: (state) => state.keywords
  },
  mutations: {},
  actions: {
    /**
     * 키워드 목록 조회
     */
    async fetchBidWeightsKeywords({ dispatch }) {
      const response = await dispatch('_fetchBidWeightsKeywords')
      return response.data
    },

    /**
     * 연령대 별 가중치 데이터 조회
     * 
     * @param {*} context 
     * @param {{ keyword: string; }} params 
     * @returns 
     */
    async fetchAgeBidWeights({ dispatch }, params) {
      const response = await dispatch('_fetchAgeBidWeights', { params })

      if (response.data.exception === null) {
        // TODO: 에러 처리
        return []
      }

      return response.data.Body.map((item) => ({
        device: item.PCM,
        active: item.Active === 'Y',
        keyword: item.Keyword,
        age: item.Age,
        bidWeight: null,
        ranks: [
          {
            date: item.수집시간_3,
            rank: item.현재순위_3,
          },
          {
            date: item.수집시간_2,
            rank: item.현재순위_2,
          },
          {
            date: item.수집시간_1,
            rank: item.현재순위_1,
          },
        ],

        iNo: item.iNo,
        customerId: item.CustomerID,
        groupId: item.GroupID,
        // 연령대에 맞는 현재 가중치를 찾기 위한 키(AG3034, AG3539, AG5054 ...)
        dictionaryCode: item.dictionaryCode,
      }))
    },

    /**
     * 현재 가중치 데이터 조회
     * 
     * @param {*} context 
     * @param {{ customerId: string; groupId: string; }} params 
     * @returns 
     */
    async fetchCurrentAgeBidWeights({ dispatch }, params) {
      const response = await dispatch('_fetchCurrentAgeBidWeights', { params })

      if (response.data.exception === null) {
        return {}
      }

      const dictionary = response.data.reduce((acc, item) => {
        acc[item.dictionaryCode] = item
        return acc
      }, {})

      return dictionary
    },

    

    /**
     * 성별 가중치 데이터 조회
     * 
     * @param {*} context 
     * @param {{ keyword: string; }} params 
     * @returns 
     */
    async fetchGenderBidWeights({ dispatch }, params) {
      const response = await dispatch('_fetchGenderBidWeights', { params })

      if (response.data.exception === null) {
        // TODO: 에러 처리
        return []
      }

      return response.data.Body.map((item) => ({
        device: item.PCM,
        active: item.Active === 'Y',
        keyword: item.Keyword,
        gender: item.Age, // 알수없음, 남성, 여성
        bidWeight: null,
        iNo: item.iNo,
        customerId: item.CustomerID,
        groupId: item.GroupID,
        dictionaryCode: item.dictionaryCode,
      }))
    },

    /**
     * 성별 현재 가중치 데이터 조회
     * 
     * @param {*} context 
     * @param {{ customerId: string; groupId: string; }} params 
     * @returns 
     */
    async fetchCurrentGenderBidWeights({ dispatch }, params) {
      const response = await dispatch('_fetchCurrentGenderBidWeights', { params })

      if (response.data.exception === null) {
        return {}
      }

      const dictionary = response.data.reduce((acc, item) => {
        acc[item.dictionaryCode] = item
        return acc
      }, {})

      return dictionary
    },

    /**
     * 현재 가중치 데이터 조회
     * 
     * @param {*} context 
     * @param {{ customerId: string; groupId: string; dictionaryCode: string; bidWeight: number; }} data 
     * @returns 
     */
    async updateAgeBidWeights({ dispatch }, data) {
      const response = await dispatch('_updateAgeBidWeights', { 
        data: {
          dictionaryCode: data.dictionaryCode,
          bidWeight: data.bidWeight
        },
        params: {
          customerId: data.customerId,
          groupId: data.groupId
        }
      })

      if (response.data.exception === null) {
        throw new Error('가중치 변경에 실패했습니다.')
      }

      return response.data
    },
  }
}