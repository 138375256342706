import Vue from 'vue'
import Vuex from 'vuex'

import { applyMixin } from './storeMixin'
import actions from './actions'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import common from './common'
import authentication from './authentication'
import filterParam from './filterParam'
import xlsx from './xlsx'
import bid from './bid'
import bidWeights from './bid-weights'

Vue.use(Vuex)

export default new Vuex.Store(
  applyMixin({
    actions,
    modules: {
      app,
      appConfig,
      verticalMenu,
      common,
      authentication,
      filterParam,
      xlsx,
      bid,
      bidWeights
    }
  })
)
